<template>
  <div />
</template>

<script>

export default {
  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Templates',
      back: { name: 'Home' }
    })
  }
}
</script>
